export default [
  {
    path: "/admin",
    component: () => import("./components/admin/index.vue"),
    meta: {
      title: "پنل مدیریت",
    },
    children: [
      {
        path: "",
        component: () => import("./components/admin/dashboard/home.vue"),
        meta: {
          title: "پنل مدیریت",
        },
      },
      {
        path: "/admin/admins",
        component: () => import("./components/admin/admins"),
      },
      {
        path: "/admin/attributes",
        component: () => import("./components/admin/attributes"),
      },
      {
        path: "/admin/banner",
        component: () => import("./components/admin/banner"),
      },
      {
        path: "/admin/brands",
        component: () => import("./components/admin/brands"),
      },
      {
        path: "/admin/colors",
        component: () => import("./components/admin/colors"),
      },
      {
        path: "/admin/coupons",
        component: () => import("./components/admin/coupons"),
      },
      {
        path: "/admin/customers",
        component: () => import("./components/admin/customers"),
      },
      {
        path: "/admin/sellers",
        component: () => import("./components/admin/sellers"),
      },
      {
        path: "/admin/seller/provide/:id",
        component: () => import("./components/admin/providesSeller"),
        name: "provides.seller",
      },
      {
        path: "/admin/store",
        component: () => import("./components/admin/store"),
      },
      {
        path: "/admin/sizes",
        component: () => import("./components/admin/sizes"),
      },
      {
        path: "/admin/product-groups",
        component: () => import("./components/admin/productGroup"),
      },
      {
        path: "/admin/blogs",
        component: () => import("./components/admin/blogs/index"),
      },
      {
        path: "/admin/blogs/comments",
        component: () => import("./components/admin/blogs/comments"),
      },
      {
        path: "/admin/blogs/create",
        component: () => import("./components/admin/blogs/create"),
      },
      {
        path: "/admin/blogs/:blog/edit",
        component: () => import("./components/admin/blogs/edit"),
        name: "blogs.edit.index",
      },
      {
        path: "/admin/blogs/categories",
        component: () => import("./components/admin/blogs/categories"),
      },
      {
        path: "/admin/comments",
        component: () => import("./components/admin/comments"),
      },
      {
        path: "/admin/seller-faq",
        component: () => import("./components/admin/seller-faq"),
      },
      {
        path: "/admin/faq",
        component: () => import("./components/admin/faq/index"),
      },
      {
        path: "/admin/faq/categories",
        component: () => import("./components/admin/faq/categories"),
      },
      {
        path: "/admin/page",
        component: () => import("./components/admin/pages/index"),
      },
      {
        path: "/admin/page/create",
        component: () => import("./components/admin/pages/create"),
      },
      {
        path: "/admin/page/:page/edit",
        component: () => import("./components/admin/pages/edit"),
        name: "page.edit.index",
      },
      {
        path: "/admin/gifts",
        component: () => import("./components/admin/gifts"),
      },
      {
        path: "/admin/provides",
        component: () => import("./components/admin/sale/provides"),
      },
      {
        path: "/admin/payment",
        component: () => import("./components/admin/sale/sellerPay"),
      },
      {
        path: "/admin/sales",
        component: () => import("./components/admin/sale/sales"),
      },
      {
        path: "/admin/sale/:id",
        component: () => import("./components/admin/sale/orderDetail"),
        name: "order.detail",
      },
      {
        path: "/admin/invoices",
        component: () => import("./components/admin/sale/invoices"),
      },
      {
        path: "/admin/invoices/:id",
        component: () => import("./components/admin/sale/details"),
      },
      {
        path: "/admin/settings",
        component: () => import("./components/admin/setting/setting"),
      },
      {
        path: "/admin/settings/general",
        component: () => import("./components/admin/setting/general"),
      },
      {
        path: "/admin/settings/social",
        component: () => import("./components/admin/setting/social"),
      },
      {
        path: "/admin/settings/shipping",
        component: () => import("./components/admin/setting/shipping"),
      },
      {
        path: "/admin/products",
        component: () => import("./components/admin/products/index"),
        name: "products",
      },
      // {
      //   path: '/admin/products/create',
      //   component: () => import('./components/admin/products/create')
      // },
      {
        path: "/admin/products/categories",
        component: () => import("./components/admin/products/categories"),
      },
      {
        path: "/admin/categories/sort",
        component: () => import("./components/admin/category/CategorySort"),
      },
      {
        path: "/admin/products/:product/:id?",
        component: () => import("./components/admin/products/create"),
        name: "products.edit.index",
        
      },
      {
        path: "/admin/discounts",
        component: () => import("./components/admin/discounts"),
      },
      {
        path: "/admin/countries",
        component: () => import("./components/admin/area/countries"),
      },
      {
        path: "/admin/provinces",
        component: () => import("./components/admin/area/provinces"),
      },
      {
        path: "/admin/cities",
        component: () => import("./components/admin/area/cities"),
      },
      {
        path: "/admin/slider",
        component: () => import("./components/admin/slider"),
      },
      {
        path: "/admin/contacts",
        component: () => import("./components/admin/contacts"),
      },
      {
        path: "/admin/menu",
        component: () => import("./components/admin/menu"),
      },
      {
        path: "/admin/menu/:id/items",
        component: () => import("./components/admin/menu/items"),
        name: "admin.menu.items",
      },
      {
        path: "/admin/menu/:id/subMenu",
        component: () => import("./components/admin/menu/subMenu"),
        name: "admin.menu.subMenu",
      },
      {
        path: "/admin/tickets",
        component: () => import("./components/admin/tickets/index.vue"),
      },
      {
        path: "/admin/tickets/:id",
        component: () => import("./components/admin/tickets/show.vue"),
        name: "admin.ticket.show",
      },
      {
        path: "/admin/lotteries",
        component: () => import("./components/admin/lotteries.vue"),
      },
      {
        path: "/admin/recommendation-groups",
        component: () =>
          import("./components/admin/recommendations/RecommendationGroups"),
        meta: {
          title: "محصولات پیشنهادی",
        },
      },
      {
        path: "/admin/recommendations/:group",
        component: () =>
          import("./components/admin/recommendations/Recommendation"),
        meta: {
          title: "محصولات پیشنهادی",
        },
        props: true,
        name: "recommendation",
      },
    ],
  },
  {
    path: "/admin/login",
    component: () => import("./components/admin/auth/login.vue"),
    meta: {
      title: "ورود به پنل مدیریت",
    },
  },
  {
    path: "/admin/invoice/print/:id",
    component: () => import("./components/admin/print/print"),
    name: "admin.invoice.print",
  },
  {
    path: "/panel",
    component: () => import("./components/seller/index.vue"),
    meta: {
      title: "پنل فروشنده",
    },
    children: [
      {
        path: "",
        component: () => import("./components/seller/dashboard.vue"),
        meta: {
          title: "پنل فروشنده",
        },
      },
      {
        path: "/panel/products",
        component: () => import("./components/seller/products/index.vue"),
        meta: {
          title: "کالاها",
        },
      },
      {
        path: "/panel/product/:id",
        component: () => import("./components/seller/products/detail.vue"),
        name: "panel.product.detail",
        meta: {
          title: "",
        },
      },
      {
        path: "/panel/product/store/:id",
        component: () => import("./components/seller/products/store.vue"),
        name: "product.store",
        meta: {
          title: "",
        },
      },
      {
        path: "/panel/provides",
        component: () => import("./components/seller/provides.vue"),
        meta: {
          title: "سفارشات",
        },
      },
      {
        path: "/panel/payment",
        component: () => import("./components/seller/payment.vue"),
        meta: {
          title: "مالی",
        },
      },
      {
        path: "/panel/profile",
        component: () => import("./components/seller/profile.vue"),
        meta: {
          title: "پروفایل",
        },
      },
      {
        path: "/panel/tickets",
        component: () => import("./components/seller/tickets/index.vue"),
        meta: {
          title: "تیکت ها",
        },
      },
      {
        path: "/panel/ticket/:id",
        component: () => import("./components/seller/tickets/show.vue"),
        meta: {
          title: "نمایش تیکت",
        },
        name: "ticket.show",
      },
      {
        path: "/panel/logs/sales",
        component: () => import("./components/seller/logs/sales.vue"),
        meta: {
          title: "گزارشات فروش",
        },
      },
      {
        path: "/panel/logs/products",
        component: () => import("./components/seller/logs/products.vue"),
        meta: {
          title: "گزارشات کالاها",
        },
      },
    ],
  },
  {
    path: "/panel/login",
    component: () => import("./components/seller/auth/login.vue"),
    meta: {
      title: "ورود به پنل فروشنده",
    },
  },
  {
    path: "/seller",
    component: () => import("./components/main/seller.vue"),
    meta: {
      title: "فروشنده",
    },
  },
  {
    path: "/prize",
    component: () => import("./components/main/lottery.vue"),
    meta: {
      title: "قرعه‌کشی",
    },
  },
  {
    path: "/",
    component: () => import("./components/main/index.vue"),
    children: [
      {
        path: "/",
        component: () => import("./components/main/home.vue"),
        meta: {
          title: "آلبا مارکت ",
        },
      },
      {
        path: "/products",
        component: () => import("./components/main/products.vue"),
        meta: {
          title: "محصولات آلبا ",
          transition: "router-transition",
        },
      },
      {
        path: "/products/category/:category/:slug?",
        component: () => import("./components/main/products.vue"),
        meta: {
          title: "محصولات",
        },
      },
      {
        path: "/product/:id/:slug?",
        component: () => import("./components/main/product-detail.vue"),
        name: "product.detail",
      },
      {
        path: "/product-category",
        component: () => import("./components/main/product-category.vue"),
        meta: {
          title: "دسته بندی محصولات",
        },
      },
      {
        path: "/blogs",
        component: () => import("./components/main/blogs.vue"),
        meta: {
          title: "بلاگ ها",
        },
      },
      {
        path: "/blogs/category/:category/:slug",
        component: () => import("./components/main/blogs.vue"),
        meta: {
          title: "بلاگ ها",
        },
      },
      {
        path: "/blogs/:id/:slug",
        component: () => import("./components/main/blog-detail.vue"),
        meta: {
          title: "بلاگ",
        },
        name: "blog.detail",
      },
      {
        path: "/page/:id/:slug?",
        component: () => import("./components/main/pages.vue"),
        meta: {
          title: "صفحات",
        },
        name: "pages.detail",
      },
      {
        path: "/brands",
        component: () => import("./components/main/brands"),
        meta: {
          title: "برند ها",
        },
      },
      {
        path: "/compare",
        component: () => import("./components/main/ComparePage"),
        meta: {
          title: "مقایسه",
        },
      },
      {
        path: "/about-us",
        component: () => import("./components/main/about-us.vue"),
        meta: {
          title: "درباره ما",
        },
      },
      {
        path: "/cart",
        component: () => import("./components/main/cart.vue"),
        meta: {
          title: "سبد خرید",
        },
      },
      {
        path: "/contact",
        component: () => import("./components/main/contact.vue"),
        meta: {
          title: "تماس با ما",
        },
      },
      {
        path: "/profile",
        component: () => import("./components/main/profile"),
        meta: {
          title: "پروفایل کاربری",
        },
      },
      {
        path: "/invoices/:id",
        component: () => import("./components/main/ProfileInvoiceSingle"),
        meta: {
          title: "صورت حساب",
        },
      },
      {
        path: "/faq",
        component: () => import("./components/main/faq.vue"),
        meta: {
          title: "سوالات متداول",
        },
      },
    ],
  },
];
